
.u-transition-expand-enter-active,
.u-transition-expand-leave-active {
    transition: height 0.2s, opacity 0.2s;
    overflow: hidden;
}

.u-transition-expand-enter,
.u-transition-expand-leave-to {
    opacity: 0;
    height: 0;
}
