

.kiwi-autocomplete {
    box-sizing: border-box;
    overflow-y: auto;
    position: absolute;
    bottom: 100%;
    right: 0;
    left: 0;
    z-index: 1;
}

.kiwi-autocomplete-item {
    padding: 5px 2em;
}

.kiwi-autocomplete-item-value {
    font-weight: bold;
}

.kiwi-autocomplete-item-action {
    float: right;
    font-size: 0.9em;
}

