

.kiwi-typinguserslist {
    position: absolute;
    top: -24px;
    background: var(--brand-default-bg);
    font-size: 0.9em;
    overflow: hidden;
    max-height: 23px;
}

