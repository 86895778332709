
.kiwi-invitelist-inviteonly-status {
    margin-top: 10px;
}

.kiwi-invitelist-table {
    width: 100%;
    border-collapse: collapse;
    line-height: 20px;
    margin-top: 10px;
}

.kiwi-invitelist-table-invitedat {
    min-width: 150px;
}

.kiwi-invitelist-table-actions {
    min-width: 50px;
    text-align: center;
    cursor: pointer;
    position: relative;
    transition: all 0.3s;
    z-index: 1;
}

.kiwi-invitelist-addmask {
    display: flex;
}

.kiwi-invitelist-addmask > button {
    flex-shrink: 0;
}
