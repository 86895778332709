

.u-input-text {
    position: relative;
    margin: 0 0 20px 0;
    box-sizing: border-box;
}

.u-input-text-inputs {
    display: flex;
    position: relative;
}

.u-input-text input,
.u-input-text textarea {
    box-sizing: border-box;
    flex: 1;
}

.u-input-text input:focus {
    outline: none;
}

.u-input-text-c {
    position: relative;
}

/* Remove spinners from input numbers */
.u-input-text input[type='number'] {
    /* For Firefox */
    -moz-appearance: textfield;
}

.u-input-text input[type='number']::-webkit-inner-spin-button,
.u-input-text input[type='number']::-webkit-outer-spin-button {
    /* For webkit browsers like Safari and Chrome */
    -webkit-appearance: none;
    margin: 0;
}

input[type='text'].u-form-input-plaintext,
input[type='password'].u-form-input-plaintext {
    padding-right: 40px;
}

.u-input-text-plaintext {
    line-height: normal;
    width: 30px;
    text-align: center;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.2s;
    position: absolute;
    right: 7px;
    top: 9px;
}

.u-form--big .u-input-text-plaintext {
    line-height: 40px;
    top: 6px;
}

.u-input-text-plaintext--active,
.u-input-text-plaintext:hover {
    opacity: 1;
}

