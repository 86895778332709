
.kiwi-channel-options-header {
    text-align: left;
    padding: 0 0 0 10px;
    margin: 0;
    opacity: 1;
    cursor: default;
    float: left;
    width: 100%;
    box-sizing: border-box;
}

.kiwi-channel-options-header span {
    padding: 5px 0;
    float: left;
    font-size: 1.2em;
    font-weight: 600;
}

.kiwi-statebrowser-network-header {
    display: block;
    padding-right: 0;
    position: relative;
    overflow: hidden;
    height: auto;
    box-sizing: border-box;
}

.kiwi-statebrowser-network-name {
    flex: 1;
    font-size: 1.1em;
    text-align: center;
    display: block;
    padding: 4px 0;
    box-sizing: border-box;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.kiwi-network-name-options {
    position: absolute;
    top: 0;
    height: 45px;
    z-index: 10;
    right: -300px;
    transition: all 0.15s;
}

.kiwi-statebrowser-network-header:hover .kiwi-network-name-options {
    right: 0;
    opacity: 1;
}

.kiwi-network-name-option {
    float: right;
    width: 35px;
    transition: all 0.15s;
    padding: 0;
    line-height: 45px;
    text-align: center;
    cursor: pointer;
}

.kiwi-statebrowser-network-toggable-area--collapsed {
    display: none;
}

.kiwi-statebrowser-network-status {
    text-align: center;
    padding: 4px 4px 6px 4px;
    overflow: hidden;
    position: relative;
    height: 1.5em;
    font-size: 0.9em;
}

.kiwi-statebrowser-network-options {
    line-height: 1em;
    text-align: right;
}

.kiwi-statebrowser-network-option {
    display: inline-block;
    width: 38px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    opacity: 0.8;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
}

.kiwi-statebrowser-channels-header {
    line-height: 35px;
    display: flex;
    font-size: 0.8em;
    text-transform: uppercase;
    cursor: pointer;
}

.kiwi-statebrowser-channels-toggle {
    width: 10px;
    line-height: 35px;
}

.kiwi-statebrowser-channels-option {
    display: inline-block;
    width: 35px;
    line-height: 35px;
    text-align: center;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 0.2s;
}

.kiwi-statebrowser-channels-option:hover {
    opacity: 1;
}

.kiwi-statebrowser-channel {
    position: relative;
    display: flex;
    border-left: 3px solid transparent;
}

.kiwi-statebrowser-channel:hover .kiwi-statebrowser-channel-name {
    text-decoration: underline;
}

.kiwi-statebrowser-channel-name,
.kiwi-statebrowser-buffertype {
    cursor: pointer;
    flex: 1;
    word-break: break-all;
    text-align: left;
    margin-left: 5px;
    user-select: none;
    transition: padding 0.1s, border 0.1s;
}

/* Contains the labels and close icons */
.kiwi-statebrowser-buffer-actions {
    flex: 0;
}

.kiwi-statebrowser-channel-labels {
    height: 100%;
    line-height: 1em;
    display: flex;
    box-sizing: border-box;
}

.kiwi-statebrowser-channel-label {
    padding: 0 10px;
    margin: 5px;
    font-weight: 600;
    border-radius: 4px;

    /* Vertical+horizontaly center align text */
    display: flex;
    text-align: center;
    align-items: center;
}

.kiwi-statebrowser-network-header .kiwi-statebrowser-buffer-actions {
    padding-right: 10px;
}

.kiwi-statebrowser-network-header .kiwi-statebrowser-channel-label {
    margin: 10px 0 10px 10px;
}

.kiwi-statebrowser-channel-leave {
    width: 38px; /* Visualy the same width as a single digit label */
    cursor: pointer;
    margin-right: 0;
    z-index: 10;
    display: none;
}

.kiwi-statebrowser-prompt-close,
.kiwi-statebrowser-prompt-close .u-input-confirm {
    padding-top: 6px;
    display: block;
}

/*
    Hovering over the buffer name should show the close icon, but hide labels
    An active buffer should always show the close icon
*/
.kiwi-statebrowser-channel:hover .kiwi-statebrowser-channel-leave,
.kiwi-statebrowser-channel-active .kiwi-statebrowser-channel-leave {
    display: block;
}

.kiwi-statebrowser-channel:hover .kiwi-statebrowser-channel-labels,
.kiwi-statebrowser-channel-active .kiwi-statebrowser-channel-labels {
    display: none;
}

/* Add channel input */
.kiwi-statebrowser-newchannel-inputwrap {
    position: relative;
    opacity: 1;
    transition: opacity 0.3s;
    background: none;
    padding: 0;
    margin: 0 0 0 0;
    box-sizing: border-box;
}

.kiwi-statebrowser-newchannel-inputwrap input[type='text'] {
    width: 100%;
    height: 40px;
    padding: 0 15px;
    line-height: 40px;
    font-size: 0.8em;
    box-sizing: border-box;
    border: none;
    margin: 0;
    border-radius: 0;
    min-height: none;
    overflow-x: hidden;
    overflow-y: auto;
    max-width: none;
}

.kiwi-statebrowser-newchannel-inputwrap--focus {
    opacity: 1;
}

/* Channel search input */
.kiwi-statebrowser-channelfilter {
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    position: relative;
    opacity: 1;
    transition: all 0.3s;
    margin-bottom: 0;
}

.kiwi-statebrowser-channelfilter:hover {
    opacity: 1;
}

.kiwi-statebrowser-channelfilter input {
    width: 100%;
    height: 42px;
    line-height: 42px;
    padding: 0 15px;
    border: none;
    border-radius: 0;
    box-sizing: border-box;
}

.kiwi-statebrowser-channelfilter p {
    text-align: center;
    font-size: 0.9em;
    margin: 10px 0 10px 0;
    cursor: pointer;
    transition: all 0.3s;
}

.kiwi-statebrowser-channelfilter p:hover {
    text-decoration: underline;
}

@media screen and (max-width: 769px) {
    .kiwi-network-name-options {
        right: 0;
        opacity: 1;
    }

    .kiwi-statebrowser-channel-name {
        line-height: 40px;
    }

    .kiwi-network-name-option {
        width: 50px;
    }

    .kiwi-statebrowser-channel-leave {
        opacity: 1;
        line-height: 40px;
        width: 50px;
    }

    .kiwi-statebrowser-channel-labels {
        right: 50px;
        top: 0;
    }

    .kiwi-statebrowser-channel-label {
        line-height: 41px;
        height: 40px;
    }

    /* Ensure that on mobile devices, when hovering this is visible */
    .kiwi-statebrowser-channel:hover .kiwi-statebrowser-channel-labels {
        opacity: 1;
    }
}

