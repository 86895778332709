
.u-input-prompt-inputs {
    display: flex;
}

.u-input-prompt-inputs > input {
    flex: 1;
    margin-right: 1em;
}

.u-input-prompt-inputs > a {
    margin-right: 0.5em;
}

.u-input-prompt-inputs > .u-button-primary {
    position: relative;
}

.u-input-prompt-hidden {
    visibility: hidden;
}

.u-input-prompt-waiting {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    font-size: 150%;
    font-weight: 800;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
