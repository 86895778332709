
.kiwi-messageinfo {
    display: block;
    position: relative;
    padding: 0;
}

.kiwi-messageinfo-urls {
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid;
    border-bottom: 1px solid;
    overflow: hidden;
    width: 100%;
}

.kiwi-messageinfo-url {
    margin-bottom: 5px;
    display: flex;
}

.kiwi-messageinfo-url .u-button {
    line-height: 30px;
}

.kiwi-messageinfo-urls .kiwi-messageinfo-url:last-of-type {
    margin-bottom: 0;
}

.kiwi-messageinfo-actions {
    margin-top: 10px;
    overflow: hidden;
    width: 100%;
    text-align: left;
}

.kiwi-messageinfo-url .u-link {
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
    flex: 1;
    line-height: 30px;
    margin-left: 10px;
    padding: 5px 10px;
    text-align: center;
    border: 1px solid;
    border-radius: 3px;
}

.kiwi-messageinfo-close {
    position: absolute;
    right: 20px;
    bottom: 5px;
    font-size: 1.3em;
    cursor: pointer;
}

.kiwi-messageinfo-opbuttons {
    margin-left: 2em;
    display: inline-block;
}

.kiwi-messageinfo-actions--open .kiwi-messageinfo-opbuttons {
    margin-left: 0;
}

.kiwi-messageinfo-opbuttons .u-input-prompt a,
.kiwi-messageinfo-reply {
    padding: 5px 10px;
    display: inline-block;
    border-radius: 4px;
}

.kiwi-messageinfo-opbuttons .u-input-prompt input {
    margin-bottom: 5px;
}

@media screen and (max-width: 490px) {
    .kiwi-messageinfo-actions {
        text-align: center;
    }

    .kiwi-messageinfo-opbuttons {
        margin: 0;
    }

    .kiwi-messageinfo-opbuttons .u-input-prompt a {
        margin-top: 10px;
    }
}
