

.kiwi-welcome-znc h2 {
    font-size: 1.7em;
    text-align: center;
    padding: 0;
    margin: 0.5em auto 1.5em auto;
}

.kiwi-welcome-znc-error {
    text-align: center;
    margin: 1em 0;
    padding: 0.3em;
}

.kiwi-welcome-znc-error span {
    display: block;
    font-style: italic;
}

.kiwi-welcome-znc-form {
    width: 300px;
    background-color: #fff;
    border-radius: 0.5em;
    padding: 1em;
    border: 1px solid #ececec;
}

.kiwi-welcome-znc .u-input-text,
.kiwi-welcome-znc .kiwi-welcome-znc-have-password input {
    margin-bottom: 1.5em;
}

.kiwi-welcome-znc-have-password input:checked {
    margin-bottom: 0;
}

.kiwi-welcome-znc-start {
    font-size: 1.1em;
    cursor: pointer;
    width: 100%;
    margin: 1em auto 0.5em auto;
    font-weight: normal;
    border: none;
    height: 36px;
    line-height: 36px;
    padding: 0;
}

.kiwi-welcome-znc-start[disabled] {
    cursor: not-allowed;
}

