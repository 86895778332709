

.kiwi-settings-aliases-input {
    width: 80%;
    height: 200px;
    font-size: 0.8em;
    line-height: 1.4em;
    font-family: monospace;
}

.kiwi-settings-aliases-showhelp {
    display: block;
}

.kiwi-settings-aliases-help {
    padding: 1em;
    margin: 1em 0;
    border: 1px dashed rgba(0, 0, 0, 0.4);
}

.kiwi-settings-aliases .u-form textarea {
    min-height: 300px;
}

.kiwi-settings-aliases-help em {
    padding: 1px 2px;
    font-weight: 900;
}
